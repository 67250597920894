<template>
  <div>
    <v-navigation-drawer
      v-if="showDrawerLeft"
      ref="drawerLeft"
      :class="!this.$vuetify.theme.dark ? 'grey lighten-3' : ''"
      class="drawer-left-wrapper pr-1"
      v-model="drawerLeft"
      mobile-breakpoint="960"
      clipped
      :width="drawerLeftWidth"
      disable-route-watcher
      :absolute="absolute"
      :app="app"
    >
      <div class="d-flex justify-space-between">
        <navigation-menu class="flex-grow-1 text-truncate" />
        <template v-if="$vuetify.breakpoint.smAndDown">
          <div>
            <v-container
              class="mt-n1 drawer-left pr-1"
            >
              <v-row>
                <v-col>
                  <div>
                    <v-btn
                      fab
                      small
                      @click.stop="drawerLeft = !drawerLeft"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </template>

      </div>

      <div
        :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
      >
        <slot name="drawer-left" />
      </div>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-right
      clipped-left
      extension-height="0"
      :class="!this.$vuetify.theme.dark ? 'grey lighten-5' : ''"
    >
      <v-app-bar-nav-icon
        v-if="backButton"
        @click.stop="goBack()"
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : 'mr-0'"
      >
        <v-icon size="28">
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        v-if="showDrawerLeft"
        @click.stop="drawerLeft = !drawerLeft"
        class="d-md-none mr-2"
      >
        <v-icon
          v-if="filterButton"
          class="d-md-none"
        >
          mdi-tune
        </v-icon>
        <v-icon
          v-if="bookmarkButton"
          class="d-md-none"
        >
          mdi-bookmark-multiple
        </v-icon>
      </v-app-bar-nav-icon>
      <div
        class="d-none px-0 d-md-block"
        style="width: 312px !important;"
      >
        <v-toolbar-title>
          <slot name="identity">
            <a
              href="/"
              class="d-flex align-start"
            >
              <img
                style="cursor: pointer;"
                :src="logoSrc"
                alt="Polpo - Politieke monitoring"
                height="44"
              >
            </a>
          </slot>
        </v-toolbar-title>
      </div>
      <slot name="searchbar">
        <v-spacer class="d-flex px-0" />
      </slot>

      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        icon
        @click="goTawk"
      >
        <v-icon>
          mdi-chat-outline
        </v-icon>
      </v-btn>

      <v-btn
        v-if="!isIE11"
        :icon="$vuetify.breakpoint.smAndDown"
        :text="$vuetify.breakpoint.mdAndUp"
        class="ak-trigger ml-sm-1"
      >
        <v-icon v-if="$vuetify.breakpoint.smAndDown">
          mdi-bell-outline
        </v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('generic.news')}}</span>
        <AnnounceKit
          catch-click=".ak-trigger"
          widget="https://announcekit.app/widgets/v2/nTBvO"
        />
      </v-btn>

      <nav-account />

      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown && showDrawerRight"
        @click.stop="drawerRight = !drawerRight"
        class="d-lg-none"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-app-bar-nav-icon>

      <appbar-progress-bar
        class="ml-n4"
        style="width: 100vw;"
        slot="extension"
      />
    </v-app-bar>

    <div
      id="main-content"
      class="mt-1"
      style="margin-left: -5px;"
    >
      <slot name="main-content" />
      <message-bar />
    </div>

    <v-navigation-drawer
      :class="!this.$vuetify.theme.dark ? 'grey lighten-3' : ''"
      v-if="showDrawerRight"
      v-model="drawerRight"
      mobile-breakpoint="600"
      :width="$vuetify.breakpoint.lgAndDown ? 256 : 320"
      clipped
      right
      disable-route-watcher
      :absolute="absolute"
      :app="app"
    >
      <div>
        <template v-if="$vuetify.breakpoint.smAndDown && drawerRight">
          <v-container
            class="mt-n1 pr-4 drawer-right"
            style="position: absolute; z-index: 20000;"
          >
            <v-row>
              <v-col>
                <div
                  class="d-flex justify-end"
                >
                  <v-btn
                    fab
                    small
                    @click.stop="drawerRight = !drawerRight"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </div>
      <div
        class="mt-2"
        :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
      >
        <slot name="drawer-right" />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';
import { selectLogo } from '@/helpers/localeHelper';
import AnnounceKit from 'announcekit-vue';
// Import the logo images
import logoPolpoLightEnglish from '@/assets/logo-transparent-english.png';
import logoPolpoDark from '@/assets/polpo-logo-dark.png';
import logoPolpoLight from '@/assets/polpo-logo-light.png';

import MessageBar from '@/components/TheMessageBar.vue';
import NavAccount from './TheNavAccount.vue';
import NavigationMenu from './NavigationMenu.vue';
import AppbarProgressBar from './AppbarProgressBar.vue';

export default {

  components: {
    NavigationMenu,
    MessageBar,
    NavAccount,
    AppbarProgressBar,
    AnnounceKit,
  },

  props: {
    absolute: {
      type: Boolean,
      required: false,
      default: false,
    },
    app: {
      type: Boolean,
      required: false,
      default: true,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    bookmarkButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDrawerLeft: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDrawerRight: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    drawerLeft: null,
    isDragged: false,
    borderSize: 8,
    drawerRight: null,
  }),

  computed: {
    ...mapGetters({
      userPreferences: 'userPreferences',
      organizationPreferences: 'organizationPreferences',
      isIE11: 'isIE11',
      referrer: 'referrer',
    }),

    drawerLeftWidth: {
      get() {
        return this.userPreferences.drawerLeftWidth || JSON.parse(localStorage.getItem('drawerLeftWidth'));
      },
      set(width) {
        this.userPreferences.drawerLeftWidth = width;
        this.updatePreferences();
      },
    },


    isSmallerScreenSizeDevive() {
      if (window.matchMedia('(max-width: 1440px)').matches) {
        return true;
      }
      return false;
    },

    logoSrc() {
      const logoType = selectLogo(this.userPreferences.locale, this.$vuetify.theme.dark);
      switch (logoType) {
        case 'light-english':
          return logoPolpoLightEnglish;
        case 'dark':
          return logoPolpoDark;
        case 'light':
        default:
          return logoPolpoLight;
      }
    },
  },

  methods: {
    updatePreferences() {
      this.$store.dispatch('setPreferences', {
        user: this.userPreferences,
        organization: this.organizationPreferences,
      });
    },

    setBorderStyle() {
      const i = this.$refs.drawerLeft.$el.querySelector(
        '.v-navigation-drawer__border',
      );
      i.style.WebkitUserSelect = 'none';
      i.style.userSelect = 'none';
      i.style.width = `${this.borderSize}px`;
      i.style.cursor = 'ew-resize';
      i.style.paddingRight = '4px';
    },

    setEvents() {
      const minSize = this.borderSize;
      const el = this.$refs.drawerLeft.$el;
      const drawerBorder = el.querySelector('.v-navigation-drawer__border');
      const direction = el.classList.contains('v-navigation-drawer--left')
        ? 'left'
        : 'right';

      const resize = (e) => {
        document.body.style.cursor = 'ew-resize';
        const f = direction === 'left'
          ? document.body.scrollWidth - e.clientX
          : e.clientX;
        if (f < 354 && f > 128) {
          el.style.width = `${f}px`;
        }
      };

      const setDrawerWidth = async () => {
        el.style.transition = '';
        this.drawerLeftWidth = el.style.width.replace('px', '');
        document.body.style.cursor = '';
        document.removeEventListener('mousemove', resize, false);
        document.removeEventListener('mouseup', setDrawerWidth, false);
      };

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if (e.offsetX < minSize) {
            // m_pos = e.x;
            el.style.transition = 'initial';
            this.isDragged = true;
            document.addEventListener('mousemove', resize, false);
            document.addEventListener('mouseup', setDrawerWidth, false);
          }
        },
        false,
      );
    },

    goBack() {
      if (this.referrer === false) {
        if (this.$route.name === 'eu-document') {
          this.$store.dispatch('setReferrer', true);
          this.$router.push({ path: '/eu/dashboard' });
        } else {
          this.$store.dispatch('setReferrer', true);
          this.$router.push({ path: '/dashboard' });
        }
      } else {
        this.$router.go(-1);
      }
    },

    goHome() {
      this.$router.push('/');
    },

    goTawk() {
      window.open('https://monitor.polpo.nl/chat', '_blank');
    },
  },

  async mounted() {
    await this.$store.dispatch('getPreferences');

    // Get drawerLeftWidth from preferences or get it from localStorage if not available
    this.drawerLeftWidth = await this.userPreferences.drawerLeftWidth || JSON.parse(localStorage.getItem('drawerLeftWidth'));

    if (this.$vuetify.breakpoint.mdAndUp) {
      this.setBorderStyle();
      this.setEvents();
    }
  },

};
</script>

<style lang="scss"> // Carefull, block not scoped
  // We want to suppress certain styles explicitly on IE because it sucks!
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
    .drawer-left, .drawer-right {
      display: none;
    }
  }

  .v-appbar__extension {
    padding: 0px !important;
  }

  .drawer-left-wrapper .v-navigation-drawer__border {
    background: linear-gradient(
      to right,
      transparent 40%,
      rgba(0,0,0,0.12) 40% 60%,
      transparent 60%
    ) !important;
  }

  @media only screen and (min-width: 992px) {
    .drawer-left-wrapper .v-navigation-drawer__border:hover {
      background: linear-gradient(
        to right,
        transparent 40%,
        #26a69a 40% 60%,
        transparent 60%
      ) !important;
    }
  }
</style>
