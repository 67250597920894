<template>
  <div>
    <v-card-title class="text-subtitle-1 pb-2">{{ $t('savedSearches.savedSearches') }}</v-card-title>
    <div
      v-if="componentReady"
    >
      <v-card-text
        class="pt-0"
      >
        <v-tabs
          v-model="savedSearchTypeTab"
          v-show="hasAccessToMultipleDashboards"
          show-arrows
        >
          <!-- We check the MC11 module for NL dashboard,
          so this does not support having the NL dashboard without MC13 module -->
          <v-tab
            v-show="authHasModuleAccess('MC11')"
          >
            NL
          </v-tab>
          <v-tab
            v-show="authHasModuleAccess('MC07')"
          >
            EU
          </v-tab>
          <v-tab
            v-show="authHasModuleAccess('MC13')"
          >
            Prov/Gem
          </v-tab>
          <v-tab
            v-show="authHasModuleAccess('MC05')"
          >
            Agenda
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="savedSearchTypeTab">
          <!-- Repeat the first tab because NL and EU are almost identical -->
          <v-tab-item
            v-for="(savedSearchList, index) in [
              {type: 'NL', searches: nlSavedSearchesCopy},
              {type: 'EU', searches: euSavedSearchesCopy},
              {type: 'NL-LR', searches: nlLrSavedSearchesCopy}
            ]"
            :key="index"
          >
            <v-simple-table
              v-if="savedSearchList.searches.data && savedSearchList.searches.data.length > 0"
              class='mt-4'
              dense
            >
              <thead>
                <th class="pl-4">{{ $t('savedSearches.savedSearch') }}</th>
                <th>{{ $t('email.directEmail')}}</th>
                <th>{{ $t('email.dailyEmail')}}</th>
                <th
                  v-if="authHasModuleAccess('MC08')"
                >{{ $t('email.weeklyEmail')}}</th>
                <th
                  v-if="authHasModuleAccess('MC15')"
                >RSS {{ $t('generic.on')}}</th>
              </thead>
              <tbody>
                <tr
                  v-for="ss in savedSearchList.searches.data"
                  :key='ss.id'
                >
                  <td>{{ ss.title }}</td>
                  <td class='checkboxItem'>
                    <v-checkbox
                      v-model="ss.sendUpdatesPerEmail.realTime"
                      @change="scheduleSubmit(ss.id)"
                    />
                  </td>
                  <td class='checkboxItem'>
                    <v-checkbox
                      v-model="ss.sendUpdatesPerEmail.daily"
                      @change="scheduleSubmit(ss.id)"
                    />
                  </td>
                  <td class='checkboxItem'
                    v-if="authHasModuleAccess('MC08')"
                  >
                    <v-checkbox
                      v-model="ss.sendUpdatesPerEmail.weekly"
                      @change="scheduleSubmit(ss.id)"
                    />
                  </td>
                  <td class='checkboxItem'
                    v-if="authHasModuleAccess('MC15')"
                  >
                    <v-checkbox
                      v-model="ss.rssEnabled"
                      @change="scheduleSubmit(ss.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div
              v-if="!savedSearchList.searches.data || savedSearchList.searches.data.length === 0"
              class="pa-4"
            >
            <i18n path="account.noSavedSearchesForType">
              <template v-slot:type>{{ savedSearchList.type }}</template>
            </i18n>
             {{ $t('account.doThisFrom') }}
             <a :href="`/${savedSearchList.type.toLowerCase()}/dashboard`">
               {{ savedSearchList.type }} Dashboard.
             </a>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table
              class='mt-4'
              dense
            >
              <thead>
                <th class="pl-4">{{$t('account.savedSearch')}}</th>
                <th><span v-html="$t('account.syncMyAgenda')"/></th>
                <th><span v-html="$t('account.emailMeetingPlanning')"/></th>
                <th><span v-html="$t('account.emailChanges')"/></th>
              </thead>
              <tbody>
                <tr v-for="ss in agendaSavedSearchesCopy.data" :key='ss.id'>
                  <td>{{ ss.title }}</td>
                  <td class='checkboxItem'>
                    <v-checkbox v-model="ss.includeInWebcal" @change="scheduleSubmit(ss.id)" />
                  </td>
                  <td class='checkboxItem'>
                    <v-checkbox
                      v-model="ss.sendUpdatesPerEmail.activitiesOverview.enabled"
                      @change="scheduleSubmit(ss.id)"
                    />
                  </td>
                  <td class='checkboxItem'>
                    <v-checkbox
                      v-model="ss.sendUpdatesPerEmail.activitiesFrequent.enabled"
                      @change="scheduleSubmit(ss.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div
              v-if="!agendaSavedSearchesCopy || (agendaSavedSearchesCopy.data && agendaSavedSearchesCopy.data.length === 0)"
              class="pa-4"
            >
            <i18n path="account.noSavedSearchesForType">
              <template v-slot:type>Agenda</template>
            </i18n>
             {{ $t('account.doThisFrom') }}
             <a :href="`/agenda`">
               Agenda Dashboard.
             </a>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </div>
    <div
      class="loading"
      v-if="!componentReady"
    >
      <v-progress-circular
        indeterminate
        class="mx-auto my-8"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AccountPreferencesFormEmailSavedSearches',

  async mounted() {
    // Get all savedsearches from backend
    const promises = [];
    // We check the MC11 module for NL dashboard,
    // so this does not support having the NL dashboard without MC13 module
    if (this.authHasModuleAccess('MC11')) promises.push(this.$store.dispatch('nlGetAllSavedSearches'));
    if (this.authHasModuleAccess('MC07')) promises.push(this.$store.dispatch('euGetAllSavedSearches'));
    if (this.authHasModuleAccess('MC05')) promises.push(this.$store.dispatch('agendaGetAllSavedSearches'));
    if (this.authHasModuleAccess('MC13')) promises.push(this.$store.dispatch('nlLrGetAllSavedSearches'));
    await Promise.all(promises);

    // We copy the savedSearches so updates do not automatically get
    // applied, since those overwrite user changes.
    this.nlSavedSearchesCopy = this.nlSavedSearches;
    this.euSavedSearchesCopy = this.euSavedSearches;
    this.nlLrSavedSearchesCopy = this.nlLrSavedSearches;
    this.agendaSavedSearchesCopy = this.agendaSavedSearches;

    this.componentReady = true;

    // Set initial tab
    if (this.authHasModuleAccess('MC11')) this.savedSearchTypeTab = 0; // NL
    else if (this.authHasModuleAccess('MC07')) this.savedSearchTypeTab = 1; // EU
    else if (this.authHasModuleAccess('MC13')) this.savedSearchTypeTab = 2; // NL-LR
    else if (this.authHasModuleAccess('MC05')) this.savedSearchTypeTab = 3; // NL Agenda
  },

  data() {
    return {
      componentReady: false,
      debounceTimers: [null, null, null], // one per tab
      savedSearchTypeTab: null,
      nlSavedSearchesCopy: null,
      euSavedSearchesCopy: null,
      nlLrSavedSearchesCopy: null,
      agendaSavedSearchesCopy: null,
      updateCopy: [false, false, false, false], // one per tab
    };
  },

  computed: {
    ...mapGetters({
      nlSavedSearches: 'nlAllSavedSearches',
      euSavedSearches: 'euAllSavedSearches',
      nlLrSavedSearches: 'nlLrAllSavedSearches',
      agendaSavedSearches: 'agendaAllSavedSearches',
      authHasModuleAccess: 'authHasModuleAccess',
    }),
    hasAccessToMultipleDashboards() {
      return ([
        this.authHasModuleAccess('MC11'),
        this.authHasModuleAccess('MC13'),
        this.authHasModuleAccess('MC05'),
        this.authHasModuleAccess('MC07'),
      ].filter((d) => d).length) > 1;
    },
  },

  watch: {
    savedSearches() {
      if (this.updateCopy[0]) {
        this.nlSavedSearchesCopy = this.nlSavedSearches;
        this.updateCopy[0] = false;
      }
    },
    euSavedSearches() {
      if (this.updateCopy[1]) {
        this.euSavedSearchesCopy = this.euSavedSearches;
        this.updateCopy[1] = false;
      }
    },
    nlLrSavedSearches() {
      if (this.updateCopy[2]) {
        this.nlLrSavedSearchesCopy = this.nlLrSavedSearches;
        this.updateCopy[2] = false;
      }
    },
    agendasavedSearches() {
      if (this.updateCopy[3]) {
        this.agendasavedSearchesCopy = this.agendasavedSearches;
        this.updateCopy[3] = false;
      }
    },
  },

  methods: {
    scheduleSubmit(savedSearchId) {
      // show spinner
      this.$emit('update-save-status', 'loading');

      const tab = this.savedSearchTypeTab;

      let savedSearches;
      if (tab === 0) {
        savedSearches = this.nlSavedSearchesCopy.data;
      } else if (tab === 1) {
        savedSearches = this.euSavedSearchesCopy.data;
      } else if (tab === 2) {
        savedSearches = this.nlLrSavedSearchesCopy.data;
      } else if (tab === 3) {
        savedSearches = this.agendaSavedSearchesCopy.data;
      }

      // Mark the changed savedSearch as `changed`
      const changedSavedSearch = savedSearches.find((ss) => ss.id === savedSearchId);
      changedSavedSearch.changed = true;

      // Get all the savedsearches that have been changed (since last submit)
      const changedSavedSearches = savedSearches.filter((ss) => ss.changed);

      // clear existing timers, we only want to submit once per time period.
      clearTimeout(this.debounceTimers[tab]);

      // Call submit after a timeout
      this.debounceTimers[tab] = setTimeout(
        this.submitForm,
        1000,
        tab, // We pass `tab` as argument in case the user switches tab before the actual submit
        changedSavedSearches,
      );
    },

    async submitForm(tab, changedSavedSearches) {
      let savedSearchType;
      if (tab === 0) savedSearchType = 'nlSaveSearches';
      else if (tab === 1) savedSearchType = 'euSaveSearches';
      else if (tab === 2) savedSearchType = 'nlLrSaveSearches';
      else if (tab === 3) savedSearchType = 'agendaSaveSearches';

      // Mark savedsearches as no longer changed
      changedSavedSearches.forEach((ss) => { ss.changed = undefined; });

      const success = await this.$store.dispatch(savedSearchType, changedSavedSearches);

      if (success && success.data && success.data.access === 'Granted') {
        this.$store.dispatch('setMessage', {
          message: this.$t('account.settingsSavedSuccessfully'),
          type: 'success',
        });
        // show saved
        this.$emit('update-save-status', 'saved');
      } else {
        this.$store.dispatch('setMessage', {
          message: this.$t('account.settingsSavedError'),
          type: 'error',
        });

        // Update with the latest version from the server as we may now be out of sync.
        this.updateCopy[tab] = true;

        // hide spinner
        this.$emit('update-save-status', null);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
}

.v-data-table {
  th:first-of-type {
    text-align: left;
  }

  td.checkboxItem {
    text-align: center;
    .v-input--checkbox {
      display: inline-block;
      margin: 0;
      padding: 0;

      ::v-deep .v-input__slot{
        margin-bottom: 0;
      }
    }
  }
}
</style>
